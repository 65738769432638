import { trpc } from "@/providers/TRPCProvider"
import { UserContext } from "@/providers/UserProvider"
import { useContext } from "react"

export const useRouterAccess = () => {
	const userContext = useContext(UserContext)
	const routerToUse = userContext.shareToken ? trpc.assistantShareRouter : trpc.assistant
	const isReadOnly = !!userContext.shareToken
	const readOnlyActions = ["open", "get font", "download", "get title font", "go to site",]

	return {
		routerToUse,
		isReadOnly,
		readOnlyActions,
	}
}
