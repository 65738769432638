import React, { useContext, useRef, } from "react"
import Styles from "./CurrentConversation.module.scss"
import { useRecoilValue } from "recoil"
import PromptToolbar from "./PromptToolbar/PromptToolbar"
import classNames from "classnames"
import { AnimatePresence, m, } from "framer-motion"
import { selectorsConversations } from "@/recoil/ConversationsRecord/Selectors/Conversations"
import InitialMessage from "./InitialMessage/InitialMessage"
import MessageList from "./MessageList/MessageList"
import TermsMessage from "./TermsMessage/TermsMessage"
import { promptToolbarProps } from "./CurrentConversationAnimation"
import { selectorsUITemporary } from "@/recoil/Ui/Temporary/Selectors"
import { selectorsMessages } from "@/recoil/ConversationsRecord/Selectors/Messages"
import { UserContext } from "@/providers/UserProvider"
import DebugInfoForTest from "./DebugInfoForTest/DebugInfoForTest"
import { useTestHelper } from "@/hooks/useTestHelper"
import { useRouterAccess } from "@/hooks/useRouterAccess"

const CurrentConversation = () => {
	const userContext = useContext(UserContext)
	const activeConversationId = useRecoilValue(selectorsConversations.activeConversationId)
	const showNewChat = useRecoilValue(selectorsConversations.showNewChat)
	const areMessagesLoading = useRecoilValue(selectorsMessages.someMessageIsLoading)
	const chatContainerRef = useRef<null | HTMLDivElement>(null)
	const isInstagramBannerOpen = useRecoilValue(selectorsUITemporary.isInstagramBannerOpen)
	const testHelper = useTestHelper()
	const { isReadOnly, } = useRouterAccess()
	const conversationClasses = classNames(
		Styles.conversation_container,
		{
			[Styles.not_new_chat]: !(showNewChat),
		}
	)

	const spaceToolbarClasses = classNames(
		Styles.space_prompt_toolbar,
		{
			[Styles.has_footer]: isInstagramBannerOpen,
			[Styles.has_privacy_police]: showNewChat,
		}
	)

	const spaceChatClasses = classNames(
		Styles.space_chat_area,
		{
			[Styles.is_loading]: areMessagesLoading,
		}
	)

	return (
		<section className={conversationClasses}>
			<DebugInfoForTest />

			<section className={spaceChatClasses}
				key={activeConversationId}
				ref={chatContainerRef}
			>
				{
					showNewChat && !userContext.shareToken
						? <InitialMessage />
						: <MessageList
							htmlConversationContainerRef={chatContainerRef}
						/>
				}
			</section >

			{!isReadOnly && <section
				className={spaceToolbarClasses}
				{...testHelper?.hideOnScreenshot}
			>
				<section className={Styles.wrapper_toolbar}>
					<section className={Styles.content_toolbar}>
						<AnimatePresence>
							<m.div {...promptToolbarProps}>
								<PromptToolbar />
							</m.div>
						</AnimatePresence>
					</section>
				</section>

			</section>}

			{showNewChat && <TermsMessage />}
		</section>
	)
}

export default CurrentConversation
