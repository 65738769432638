/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect } from "react"
import { useClickOutside } from "deblank-common/src/hooks/useClickOutside"
import Styles from "./Actions.module.scss"
import DotsIcon from "deblank-common/src/assets/images/icon-library/more.svg"
import classNames from "classnames"
import { AnimatePresence, m } from "framer-motion"
import { actionListMotionProps } from "./Animations"
import { widgetAction } from "@/pages/AssistantPage/components/CurrentConversation/MessageList/WidgetSelector/WidgetsTypes"
import Spinner, { SpinnerSize } from "../Spinner/Spinner"
import { useRouterAccess } from "@/hooks/useRouterAccess"

export type CustomActionStyles = {
	actionType?: "colors" | "fonts" | "images" | "pinItem" | "pinColor" | "brands",
	buttonActionVariant?: "primary" | "ghost",
}

type Props = {
	actionList: widgetAction[],
	onSetActionsOpen?: (isOpen: boolean) => void,
	customStyles?: CustomActionStyles,
	isLoading?: boolean,
}

const Actions = (props: Props) => {

	const { isOpen, toggleIsOpen, elementRef, setIsOpen, } = useClickOutside()
	const { isReadOnly, readOnlyActions, } = useRouterAccess()
	const filteredActions = isReadOnly
		? props.actionList.filter((action) => readOnlyActions.includes(action.text.toLowerCase()))
		: props.actionList

	useEffect(() => {
		if (props.onSetActionsOpen) {
			props.onSetActionsOpen(isOpen)
		}
	}, [isOpen,])

	const wrapperClasses = classNames(
		Styles.wrapper,
		{
			[Styles.is_colors_action]: props.customStyles?.actionType === "colors",
			[Styles.is_fonts_action]: props.customStyles?.actionType === "fonts",
			[Styles.is_images_action]: props.customStyles?.actionType === "images",
			[Styles.is_pin_item_action]: props.customStyles?.actionType === "pinItem",
			[Styles.is_pin_color_action]: props.customStyles?.actionType === "pinColor",
			[Styles.is_brands_action]: props.customStyles?.actionType === "brands",
		}
	)

	const buttonActionClases = classNames(
		Styles.button_action,
		{
			[Styles.button_action_primary]: (props.customStyles?.buttonActionVariant || "primary") === "primary",
			[Styles.button_action_ghost]: props.customStyles?.buttonActionVariant === "ghost",
		}
	)

	const actionListClasses = classNames(
		Styles.action_list,
		{
			[Styles.is_brands_action]: props.customStyles?.actionType === "brands",
		}
	)

	const handleWidgetAction = (action: widgetAction) => {
		action.onClick()
		setIsOpen(false)
	}

	const handleOpenOptions = () => {
		toggleIsOpen()
	}

	return (
		<div className={wrapperClasses} ref={elementRef} >
			<button className={buttonActionClases} onClick={handleOpenOptions}>
				{
					props.isLoading
						? <Spinner size={SpinnerSize.Small} />
						: <DotsIcon />
				}
			</button>

			<AnimatePresence>
				{isOpen && !props.isLoading
					&& <m.div className={actionListClasses}
						{...actionListMotionProps}>
						{filteredActions.map((action, index) => (
							<button
								key={action.text}
								type="button"
								className={Styles.action_list_item}
								onClick={() => handleWidgetAction(action)}
								style={{
									animationDelay: `${(index) * 50}ms`,
								}}
								disabled={action.isDisabled}
							>
								<action.icon />
								<span>
									{action.text}
								</span>
							</button>
						))}
					</m.div>
				}
			</AnimatePresence>
		</div>
	)
}

export default Actions
