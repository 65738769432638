import React, { useContext, useEffect } from "react"
import { useRecoilValue } from "recoil"
import { selectorsUITemporary } from "@/recoil/Ui/Temporary/Selectors"
import { useWindowSize } from "@/hooks/useWindowsSize"
import { usePreventScroll } from "deblank-common/src/hooks/usePreventScroll"
import AssistantLayout from "./components/AssistantLayout/AssistantLayout"
import CurrentConversation from "./components/CurrentConversation/CurrentConversation"
import Sidebar from "./components/Sidebar/Sidebar"
import PinnedList from "./components/PinnedList/PinnedList"
import DebugPanel from "./components/DebugPanel/DebugPanel"
import ConversationsRecordManager from "./managers/ConversationsRecordManager/ConversationsRecordManager"
import FontsCacheManager from "./managers/FontsCacheManager/FontsCacheManager"
import { PortalRootLayoutAssistantId } from "@/components/Portal/Portal"
import { TrackConversationEventProvider } from "./TrackConversationEventProvider"
import { UserContext } from "@/providers/UserProvider"
import { trackEvent } from "@/utils/mixpanel/mixpanel"
import { ColorsPlayground } from "./components/ColorsPlayground/ColorsPlayground"
import { FontsPlayground } from "./components/FontsPlayground/FontsPlayground"
import { ViewImageModal } from "./components/ViewImageModal/ViewImageModal"
import { selectorsConversations } from "@/recoil/ConversationsRecord/Selectors/Conversations"
import ConversationSaveManager from "./managers/ConversationSaveManager/ConversationSaveManager"
import { trpc } from "@/providers/TRPCProvider"
import { settersConversations } from "@/recoil/ConversationsRecord/Setters/Conversations"
import ImageManager, { FetchParams } from "@/utils/ImageManager"
import { getRouteApi } from "@tanstack/react-router"

const AssistantPage = () => {
	const windowsSize = useWindowSize()
	const userContext = useContext(UserContext)

	const isPinnedListOpen = useRecoilValue(selectorsUITemporary.isPinnedListOpen)
	const isSidebarOpen = useRecoilValue(selectorsUITemporary.isSidebarOpen)
	const currentConversation = useRecoilValue(selectorsConversations.currentConversation)

	const updateUserConversationHistory = settersConversations.useUpdateUserConversationHistory()
	const routeApi = getRouteApi("/")
	const searchParams = routeApi.useSearch()

	const routerToUse = searchParams.shareToken ? trpc.assistantShareRouter : trpc.assistant

	const getHistoryConversations = trpc.assistant.conversation.getHistoryConversations.useQuery()
	const getSharedHistoryConversations = trpc.assistantShareRouter.conversation.getHistoryConversations.useQuery()
	const getSignedUrl = routerToUse.imageStorage.getImageSignedUrl.useMutation()

	useEffect(() => {
		if (searchParams.shareToken) {
			userContext.updateShareToken(searchParams.shareToken)
		}
	}, [])

	useEffect(() => {
		trackEvent({
			eventName: "AccessProduct",
			user: userContext.user?.displayName || userContext.user?.email || "",
			device: windowsSize.device,
			deviceWidth: window.innerWidth,
		})
	}, [])

	useEffect(() => {
		if (searchParams.shareToken) {
			if (getSharedHistoryConversations.data) {
				updateUserConversationHistory({
					conversations: getSharedHistoryConversations.data!,
				})
			}
		} else if (getHistoryConversations.data) {
			updateUserConversationHistory({
				conversations: getHistoryConversations.data!,
			})
		}
	}, [
		getHistoryConversations.data,
		getSharedHistoryConversations.data,
	])

	useEffect(() => {
		const handleFetchImage = async (params: FetchParams) => {
			return await getSignedUrl.mutateAsync({
				imageFileName: params.imageFileName,
				conversationId: params.conversationId,
			})
		}
		ImageManager.setFetchImageUrlFunction(handleFetchImage)
	}, [])

	usePreventScroll({
		prevent: (isSidebarOpen || isPinnedListOpen)
			&& (windowsSize.isMobile || windowsSize.isTablet),
	})

	return (
		<TrackConversationEventProvider activeConversationId={currentConversation?.id}>
			<ConversationSaveManager />
			<FontsCacheManager />
			<ConversationsRecordManager />

			<AssistantLayout
				sidebar={<Sidebar />}
				currentConversation={<CurrentConversation />}
				pinned={<PinnedList />}
				debug={<DebugPanel />}
				colorsPlayground={<ColorsPlayground />}
				fontsPlayground={<FontsPlayground />}
				imageModal={<ViewImageModal />}
			/>
			<div id={PortalRootLayoutAssistantId} />
		</TrackConversationEventProvider>
	)
}

export default AssistantPage
