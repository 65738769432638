/* eslint-disable @typescript-eslint/no-empty-function */
import { getAdditionalUserInfo, signInWithCustomToken, signOut, } from "firebase/auth"
import React, { useContext, useEffect, useState, } from "react"
import { createContext } from "react"
import { auth } from "@/utils/firebase"
import { GoogleAuthProvider, signInWithPopup, } from "firebase/auth"
import { trpc } from "./TRPCProvider"
import { EnvHelperClient } from "@/utils/envHelper"
import { UserContext } from "./UserProvider"
import { useNavigate } from "@tanstack/react-router"
import { trackEvent } from "@/utils/mixpanel/mixpanel"
import LoadingView from "@/components/LoadingView/LoadingView"
import { useRouterAccess } from "@/hooks/useRouterAccess"


type AuthenticationContext = {
	signInWithGoogle: () => void,
	userSignOut: () => void,
	signInWithToken: (token: string) => Promise<void>,
	userLogout: boolean,
}

export const AuthenticationContext = createContext<AuthenticationContext>({
	signInWithGoogle: () => { },
	userSignOut: () => { },
	signInWithToken: async () => { },
	userLogout: false,
})

export const AuthenticationProvider = (props: { children: React.ReactNode, }) => {

	const getCustomToken = trpc.authentication.generateCustomToken.useMutation()
	const validShareUser = trpc.authentication.checkIfAssistantUserIsValid.useMutation()
	const { isReadOnly, } = useRouterAccess()
	const [isLoading, setIsLoading,] = useState(true)
	const userContext = useContext(UserContext)
	const [userLogout, setUserLogout,] = useState(false)

	const navigation = useNavigate()

	const signInWithGoogle = async () => {
		const provider = new GoogleAuthProvider()
		const userCredentials = await signInWithPopup(auth, provider)
		const additionalInfo = getAdditionalUserInfo(userCredentials)
		userContext.setIsNewUser(additionalInfo?.isNewUser || false)

		await trackEvent({
			eventName: "Login",
			user: userCredentials.user.displayName || userCredentials.user.email || "",
			email: userCredentials.user.email || "",
			isNewUser: true,
		})

	}

	const userSignOut = async () => {
		setUserLogout(true)
		signOut(auth)

		await trackEvent({
			eventName: "Logout",
			user: userContext.user?.displayName || userContext.user?.email || "",
			email: userContext.user?.email || "",
		})
	}

	const signInWithToken = async (token: string) => {
		if (EnvHelperClient.envType?.includes("preview")) {
			const customToken = await getCustomToken.mutateAsync({ token, })
			if (customToken) {
				const credential = await signInWithCustomToken(auth, customToken)

				if (credential.user) {
					await userContext.updateUser!(credential.user)
					if (isReadOnly) {
						const isValidUser = await validShareUser.mutateAsync()
						if (!isValidUser) {
							await userContext.updateUser!(null)
							throw new Error("Invalid viewer user")
						}
					}
				} else {
					await userContext.updateUser!(null)
					throw new Error("User does not exist")
				}
			} else {
				await userContext.updateUser!(null)
				throw new Error("Invalid token")
			}
		} else {
			await userContext.updateUser!(null)
			throw new Error("Invalid environment")
		}
	}

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
			try {
				if (currentUser) {
					setUserLogout(false)
					setIsLoading(true)
					await userContext.updateUser!(currentUser)
					if (isReadOnly) {
						const isValidUser = await validShareUser.mutateAsync()
						if (!isValidUser) {
							setIsLoading(false)
							throw new Error("invalid viewer user")
						}
					}
					setIsLoading(false)
					await navigation({
						to: "/",
						replace: true,
						search: ((prev) => ({
							useDebug: prev.useDebug,
							shareToken: prev.shareToken,
						})),
					})

				} else {
					setIsLoading(false)
					await userContext.updateUser!(null)
				}
			} catch (e) {
				console.error(e)
				signOut(auth)
				setIsLoading(false)
				await userContext.updateUser!(null)
			}
		})
		return () => {
			setIsLoading(false)
			unsubscribe()
		}
	}, [userContext.user, userContext.shareToken,])

	return <AuthenticationContext.Provider
		value={{
			signInWithGoogle,
			signInWithToken,
			userSignOut,
			userLogout,
		}}
	>
		{isLoading ? <LoadingView /> : props.children}
	</AuthenticationContext.Provider >
}
