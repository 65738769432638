import React, { useContext, useState } from "react"
import Styles from "./HistoryItem.module.scss"
import classNames from "classnames"
import { useRecoilValue } from "recoil"
import { selectorsMessages } from "@/recoil/ConversationsRecord/Selectors/Messages"
import { settersConversations } from "@/recoil/ConversationsRecord/Setters/Conversations"
import { settersUiTemporary } from "@/recoil/Ui/Temporary/Setters"
import { ConversationMetadata, UserHistoryConversations } from "deblank-api-types"
import DeleteIcon from "deblank-common/src/assets/images/icon-library/Delete.svg"
import EditIcon from "deblank-common/src/assets/images/icon-library/edit.svg"
import Actions from "@/components/Actions/Actions"
import { widgetAction } from "../../../CurrentConversation/MessageList/WidgetSelector/WidgetsTypes"
import { selectorsConversations } from "@/recoil/ConversationsRecord/Selectors/Conversations"
import { TrackConversationEventContext } from "@/pages/AssistantPage/TrackConversationEventProvider"
import { useWindowSize } from "@/hooks/useWindowsSize"
import { m } from "framer-motion"
import { historyItemMotionProps } from "./HistoryItemAnimation"
import { useRouterAccess } from "@/hooks/useRouterAccess"

type Props = {
	selected: boolean,
	data: UserHistoryConversations,
}

const HistoryItem = React.forwardRef((
	props: Props,
	ref: React.ForwardedRef<HTMLLIElement>,
) => {

	const { trackConversationEvent, } = useContext(TrackConversationEventContext)
	const [actionsOpen, setActionsOpen,] = useState<boolean>(false)
	const [showInputEditName, setShowInputEditName,] = useState<boolean>(false)
	const [inputValue, setInputValue,] = useState<string>()

	const windowsSize = useWindowSize()

	const someMessageIsLoading = useRecoilValue(selectorsMessages.someMessageIsLoading)
	const historyConversations = useRecoilValue(selectorsConversations.historyConversations)
	const isSavingNewConversationName = useRecoilValue(selectorsConversations.isSavingNewConversationName)

	const setOutputIdSelected = settersUiTemporary.useSetOutputIdSelected()
	const toggleIsOpenSidebar = settersUiTemporary.useToggleIsOpenSidebar()
	const setIsOpenDebugPanel = settersUiTemporary.useSetIsOpenDebugPanel()

	const updateCurrentConversationId = settersConversations.useUpdateCurrentConversationId()
	const deleteConversationInHistory = settersConversations.useDeleteConversationById()
	const updateConversationNameById = settersConversations.useUpdateConversationNameById()
	const setIsSavingNewConversationName = settersConversations.useSetIsSavingNewConversationName()

	const { routerToUse, } = useRouterAccess()
	const deleteConversation = routerToUse.conversation.deleteConversationById.useMutation()
	const updateConversationMetadata = routerToUse.conversation.updateConversationMetadataById.useMutation()

	const disableActions = props.selected || someMessageIsLoading || isSavingNewConversationName || deleteConversation.isLoading

	const wrapperClasses = classNames(
		Styles.temporary_history_wrapper,
		"actions_handler",
		{
			[Styles.actions_open]: actionsOpen,
			[Styles.is_loading]: deleteConversation.isLoading,
			[Styles.is_active]: props.selected,
			[Styles.is_editing]: showInputEditName,
		}
	)

	const handleChangeConversation = () => {
		if (!disableActions) {
			setOutputIdSelected({ outputIdSelected: null, })
			updateCurrentConversationId({ newConversationId: props.data.id, })

			if (windowsSize.isMobile || windowsSize.isTablet) { toggleIsOpenSidebar() }

			trackConversationEvent({
				eventName: "LoadConversation",
				conversationName: props.data.metadata.conversationName,
			})
		}
	}

	const handleOnSubmit = async (e: React.FormEvent) => {
		e.preventDefault()
		await handleOnSave()
	}

	const handleOnSave = async () => {
		setIsSavingNewConversationName(true)
		if (historyConversations && inputValue && inputValue !== props.data.metadata.conversationName) {
			try {
				const metadata: ConversationMetadata = {
					...historyConversations[props.data.id].metadata,
					conversationName: inputValue,
				}

				await updateConversationMetadata.mutateAsync({
					conversationId: props.data.id,
					metadata,
				})

				trackConversationEvent({
					eventName: "ChangeConversationName",
					conversationName: inputValue,
					prevConversationName: props.data.metadata.conversationName,
				})

				updateConversationNameById({
					conversationId: props.data.id,
					newName: inputValue,
				})
			}
			catch (error) {
				handleCancelEdit()
			}
			finally {
				setIsSavingNewConversationName(false)
				setShowInputEditName(false)
			}
		} else {
			handleCancelEdit()
		}
	}

	const handleOnKeyDownInput = (e: React.KeyboardEvent) => {
		if (e.key === "Escape") {
			e.preventDefault()
			handleCancelEdit()
		}
	}

	const handleEditConversationName = () => {
		setInputValue(props.data.metadata.conversationName)
		setShowInputEditName(true)
	}

	const handleCancelEdit = () => {
		setInputValue(props.data.metadata.conversationName)
		setShowInputEditName(false)
		setIsSavingNewConversationName(false)
	}

	const handleDeleteConversation = async () => {
		try {
			setIsOpenDebugPanel({ isOpen: false, })
			await deleteConversation.mutateAsync({ conversationId: props.data.id, })
			trackConversationEvent({
				eventName: "DeleteConversation",
				conversationName: props.data.metadata.conversationName,
			})
			deleteConversationInHistory({ conversationId: props.data.id, })
		}
		catch (error) {
			console.error("Error deleting conversation", error)
			throw error
		}
	}

	const actionList: widgetAction[] = [
		{
			text: "Rename",
			icon: EditIcon,
			onClick: handleEditConversationName,
		},
		{
			text: "Delete",
			icon: DeleteIcon,
			onClick: handleDeleteConversation,
		},
	]

	return (
		<m.li className={wrapperClasses}
			ref={ref}
			{...historyItemMotionProps} >
			<div className={Styles.wrapper_project_name}>
				{showInputEditName
					? <form onSubmit={handleOnSubmit}>
						<input value={inputValue}
							className={Styles.input}
							onChange={(e) => setInputValue(e.target.value)}
							onBlur={handleOnSave}
							onKeyDown={handleOnKeyDownInput}
							disabled={isSavingNewConversationName}
							// eslint-disable-next-line jsx-a11y/no-autofocus
							autoFocus
						/>
					</form>
					: <button className={Styles.history_item}
						onDoubleClickCapture={handleEditConversationName}
						onClick={handleChangeConversation} >
						{props.data.metadata.conversationName}
					</button>
				}
			</div>
			{(!someMessageIsLoading && !showInputEditName)
				&& <div className={Styles.content_actions}>
					<Actions actionList={actionList}
						onSetActionsOpen={setActionsOpen}
						isLoading={deleteConversation.isLoading}
						customStyles={{
							actionType: "fonts",
							buttonActionVariant: "ghost",
						}}
					/>
				</div>
			}
		</m.li>
	)
})

HistoryItem.displayName = "HistoryItem"

export default HistoryItem
