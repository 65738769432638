import React, { useContext, useEffect, useRef, useState } from "react"
import Styles from "./ConversationLoadManager.module.scss"
import { trpc } from "@/providers/TRPCProvider"
import { selectorsConversations } from "@/recoil/ConversationsRecord/Selectors/Conversations"
import { settersConversations } from "@/recoil/ConversationsRecord/Setters/Conversations"
import { settersFontCache } from "@/recoil/FontCache/Setters"
import { Conversation } from "deblank-api-types"
import { useRecoilValue } from "recoil"
import { extractSlugsFromWidgets } from "./ConversationLoadManagerHelper"
import * as Sentry from "@sentry/react"
import GradientLoadingAnimation from "@/components/GradientLoadingAnimation/GradientLoadingAnimation"
import Spinner, { SpinnerSize } from "@/components/Spinner/Spinner"
import Button from "@/components/Buttons/Button/Button"
import { UserContext } from "@/providers/UserProvider"

const ConversationStateLoaderManager = () => {
	const [hasError, setHasError,] = useState(false)
	const isFetching = useRef(false)

	const loadConversationState = useRecoilValue(selectorsConversations.loadConversationState)
	const activeConversationId = useRecoilValue(selectorsConversations.activeConversationId)

	const setConversationById = settersConversations.useSetConversationById()
	const addSlugsToPendingSlugs = settersFontCache.useAddSlugsToPendingSlugs()
	const setLoadConversationState = settersConversations.useSetLoadConversationState()
	const updateCurrentConversationId = settersConversations.useUpdateCurrentConversationId()
	const userContext = useContext(UserContext)

	const getConversationById = trpc.assistant.conversation.getConversationById.useMutation()
	const getConversationByShare = trpc.assistantShareRouter.conversation.getConversationById.useMutation()

	useEffect(() => {
		if (loadConversationState && activeConversationId && !isFetching.current) {
			isFetching.current = true
			loadConversationById({
				conversationId: activeConversationId,
			})
		}

	}, [
		loadConversationState,
		activeConversationId,
	])

	useEffect(() => {
		if (userContext.shareToken) {
			isFetching.current = true
			loadConversationById({
				conversationId: undefined,
			})
		}
	}, [userContext,])

	const handleRetryLoad = () => {
		setHasError(false)
		loadConversationById({
			conversationId: activeConversationId!,
		})
	}

	const loadConversationById = async (params: {
		conversationId: string | undefined,
	}) => {
		try {
			const conversation = params.conversationId ? await getConversationById.mutateAsync({
				conversationId: params.conversationId,
			}) : await getConversationByShare.mutateAsync({})
			if (conversation && conversation.frontEndState) {
				const frontEndState = conversation.frontEndState as Conversation

				if (frontEndState.outputs) {
					const slugs = extractSlugsFromWidgets({
						widgets: frontEndState.outputs,
					})
					addSlugsToPendingSlugs(slugs || [])
				}
				setConversationById({ conversation: frontEndState, })
				if (!params.conversationId) {
					updateCurrentConversationId({ newConversationId: frontEndState.id, })
				}
				setLoadConversationState(false)
			} else {
				throw new Error("Conversation not has frontEndState")
			}
		}
		catch (error) {
			setHasError(true)
			const errorMessage = "Error loading conversation"
			console.error(errorMessage)
			console.error(error)
			Sentry.captureException(error)
		} finally {
			isFetching.current = false
		}
	}


	return (
		<section className={Styles.wrapper_gradient_animation}>
			{
				(hasError)
					? <article className={Styles.content_error_message}>
						<span className={Styles.error_message}>
							Error loading conversation
						</span>

						<Button type="button"
							onClick={handleRetryLoad}
							customStyles={{
								variant: "ghost",
								size: "small",
							}}
						>
							Retry
						</Button>
					</article>
					: <>
						<GradientLoadingAnimation />
						<span className={Styles.content_spinner}>
							<Spinner size={SpinnerSize.Small} />
						</span>
					</>
			}
		</section>
	)
}

export default ConversationStateLoaderManager
