import { User, } from "firebase/auth"
import React, { useRef, useState } from "react"
import { createContext } from "react"
import * as Sentry from "@sentry/react"
import { addUserInfo, resetUserInfo } from "@/utils/mixpanel/mixpanel"

export type UserContextType = {
	shareToken?: string,
	user: User | null,
	userRef: React.MutableRefObject<User | null>,
	isNewUser: boolean,
	updateUser: ((user: User | null) => Promise<void>) | null,
	setIsNewUser: (value: boolean) => void,
	updateShareToken: (token: string) => void,
	shareTokenRef: React.MutableRefObject<string | undefined>,
}

export const UserContext = createContext<UserContextType>(null as unknown as UserContextType)

const useUserState = () => {
	const [user, setUser,] = useState<User | null>(null)
	const userRef = useRef<User | null>(null)
	const handleUpdateUser = (user: User | null) => {
		setUser(user)
		userRef.current = user
	}

	return {
		user,
		setUser: handleUpdateUser,
		userRef,
	}
}

const useShareToken = () => {
	const shareTokenRef = useRef<string | undefined>(undefined)
	const [shareToken, setShareToken,] = useState<string | undefined>(undefined)

	const handleUpdateShareToken = (token: string) => {
		setShareToken(token)
		shareTokenRef.current = token
	}

	return {
		shareToken,
		shareTokenRef,
		updateShareToken: handleUpdateShareToken,
	}
}

export const UserProvider = (props: { children: React.ReactNode, }) => {
	const { user, setUser, userRef, } = useUserState()
	const [isNewUser, setIsNewUser,] = useState<boolean>(false)
	const { shareToken, shareTokenRef, updateShareToken, } = useShareToken()

	const handleUpdateShareToken = (token: string) => {
		updateShareToken(token)
	}

	const handleUpdateUser = (user: User | null) => {
		return new Promise<void>(
			// eslint-disable-next-line no-async-promise-executor
			async (resolve, reject) => {
				try {

					setUser(user)
					const sentryUser: Sentry.User | null = user ? {
						id: user.uid, username: user.displayName || undefined, email: user.email || undefined,
					} : null
					Sentry.setUser(sentryUser)
					if (user) {
						await addUserInfo({
							id: user.uid,
							name: user.displayName || undefined,
							email: user.email || undefined,
						})
					} else {
						await resetUserInfo()
					}

					resolve()
				} catch (e) {
					reject(e)
				}
			}
		)

	}

	const handleSetNewUser = (isNew: boolean) => {
		setIsNewUser(isNew)
	}

	return <UserContext.Provider
		value={{
			user,
			shareToken,
			userRef,
			isNewUser,
			shareTokenRef,
			updateUser: handleUpdateUser,
			setIsNewUser: handleSetNewUser,
			updateShareToken: handleUpdateShareToken,
		}}
	>
		{props.children}
	</UserContext.Provider>
}
