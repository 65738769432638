import { selectorsConversations } from "@/recoil/ConversationsRecord/Selectors/Conversations"
import { useRecoilValue } from "recoil"
import { useRouterAccess } from "./useRouterAccess"

export const useUploadFile = () => {

	const { routerToUse, } = useRouterAccess()
	const getuploadFileUrl = routerToUse.fileStorage.getSignedUrlToUploadFile.useMutation().mutateAsync
	const getSignedUrl = routerToUse.fileStorage.getFileSignedUrl.useMutation().mutateAsync
	const conversationId = useRecoilValue(selectorsConversations.activeConversationId)

	const uploadFile = async (
		file: File
	) => {

		const fileUploadUrl = await getuploadFileUrl({
			typeOfAsset: "pdf",
			clientOrigin: window.location.origin,
			conversationId: conversationId!,
		})

		const response = await fetch(fileUploadUrl.url, {
			method: "PUT",
			body: file,
		})

		if (response.ok) {
			const signedImageUrl = await getSignedUrl({
				fileName: fileUploadUrl.fileName,
				conversationId: conversationId!,
			})
			return signedImageUrl.signedUrl
		} else {
			throw new Error("Error uploading pdf")
		}
	}

	return { uploadFile, }

}
