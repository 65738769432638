import React, { useEffect, useRef, useState } from "react"
import * as Sentry from "@sentry/react"
import { useRecoilState, useRecoilValue } from "recoil"
import useConversationRecordHandlers, { ConversationState } from "./useConversationRecordHandlers"
import { selectorsAssistantResponseTools } from "@/recoil/ConversationsRecord/Selectors/AssistantResponseTools"
import { settersMessages } from "@/recoil/ConversationsRecord/Setters/Messages"
import { selectorsConversations } from "@/recoil/ConversationsRecord/Selectors/Conversations"
import { settersConversations } from "@/recoil/ConversationsRecord/Setters/Conversations"
import { selectorsIntroQuestions } from "@/recoil/ConversationsRecord/Selectors/CurrentIntroQuestions"
import { selectorsUITemporary } from "@/recoil/Ui/Temporary/Selectors"
import { useUploadFile } from "@/hooks/useUploadFile"
import { useRouterAccess } from "@/hooks/useRouterAccess"


const ConversationsRecordManager = () => {

	const isProcessingRef = useRef(false)
	const loopRetryCountRef = useRef(0)
	const [conversationState, setConversationState,] = useState<ConversationState>("off")
	const { uploadFile, } = useUploadFile()

	const pendingMessage = useRecoilValue(selectorsAssistantResponseTools.currentPendingMessage)
	const pendingNotifications = useRecoilValue(selectorsAssistantResponseTools.currentPendingNotifications)
	const activeConversationId = useRecoilValue(selectorsConversations.activeConversationId)
	const currentIntroQuestion = useRecoilValue(selectorsIntroQuestions.currentIntroQuestion)
	const [fileAttachment, setFileAttachment,] = useRecoilState(selectorsUITemporary.userFileAttachment)

	const updateMessageState = settersMessages.useUpdateMessageStateInCurrentConversation()
	const setConversationSaveState = settersConversations.useSetConversationSaveState()

	const {
		handleOnSuccessLoopResult,
		handleOnErrorLoop,
		hasError,
	} = useConversationRecordHandlers({
		loopRetryCount: loopRetryCountRef.current,
		pendingMessage: pendingMessage,
		conversationState: conversationState,
		setConversationState: setConversationState,
	})

	const { routerToUse, } = useRouterAccess()

	const getDesignIntelligenceResponse = routerToUse.conversation.designIntelligence.useMutation({
		onSuccess: (data) => (handleOnSuccessLoopResult(data)),
		onError: (error) => (handleOnErrorLoop(error)),
	})


	const processPendingMessage = async () => {

		if (!pendingMessage) {
			setConversationState("off")
			return
		}
		const { userMessage, prompt, } = pendingMessage
		let fileUrl: string | undefined

		if (conversationState === "init") {
			loopRetryCountRef.current = 0

			if (fileAttachment) {
				fileUrl = await uploadFile(fileAttachment)
			}

			await getDesignIntelligenceResponse.mutateAsync({
				conversationId: activeConversationId!,
				pendingNotifications: pendingNotifications,
				userMessage: {
					...userMessage,
					fileUrlAttachment: fileUrl,
					message: prompt ? prompt : userMessage.message,
				},
				requestParameters: {
					isInitialRequest: true,
					isAction: pendingMessage.isAction,
					isEdit: pendingMessage.isEdit,
					currentIntroQuestion: currentIntroQuestion,
				},
			})
		}

		if (conversationState === "processing" || conversationState === "retry") {
			// Create backend loop for more actions

			await getDesignIntelligenceResponse.mutateAsync({
				conversationId: activeConversationId!,
				pendingNotifications: null,
				userMessage: {
					...userMessage,
					message: prompt ? prompt : userMessage.message,
					fileUrlAttachment: fileUrl,
				},
				requestParameters: {
					isInitialRequest: false,
					isAction: pendingMessage.isAction,
					isEdit: pendingMessage.isEdit,
					currentIntroQuestion: currentIntroQuestion,
				},
			})
			loopRetryCountRef.current += 1
			return
		}

		if (conversationState === "loopDetected") {
			// When we detect that the agents are in an infinite loop.
			// We throw this case as a last way to stop loop if all others fail.

			const errorMessage = "Error assistant backend retry limit reached. Conversation looped too many times."
			Sentry.captureMessage(errorMessage)
			console.error(errorMessage)
			updateMessageState({
				messageId: pendingMessage.messageId,
				state: "error",
			})
			setConversationState("off")
			return
		}

		if (conversationState === "completed") {
			setFileAttachment(null)
			updateMessageState({
				messageId: pendingMessage.messageId,
				state: "success",
			})
			setConversationState("off")
			setConversationSaveState("pendingSave")
		}

		if (conversationState === "off") {
			loopRetryCountRef.current = 0
		}
	}

	const processConversation = async () => {
		if (!isProcessingRef.current) {
			isProcessingRef.current = true
			await processPendingMessage()
			isProcessingRef.current = false
		}
	}

	useEffect(() => {
		if (pendingMessage) {
			setConversationState("init")
		}
	}, [
		pendingMessage,
	])

	useEffect(() => {
		processConversation()
	}, [
		conversationState,
	])

	useEffect(() => {
		if (conversationState !== "off") {
			const handleBeforeUnload = (event: BeforeUnloadEvent) => {
				event.preventDefault()
				event.returnValue = ""
			}

			window.addEventListener("beforeunload", handleBeforeUnload)

			return () => {
				window.removeEventListener("beforeunload", handleBeforeUnload)
			}
		}
	}, [conversationState,])

	if (hasError) {
		throw Error(hasError)
	}
	return <></>
}

export default ConversationsRecordManager
