import { createFileRoute } from "@tanstack/react-router"
import AssistantPage from "../pages/AssistantPage/AssistantPage"

type HomeSearchParams = {
	useDebug?: boolean,
	shareToken?: string,
}

export const Route = createFileRoute("/")({
	component: AssistantPage,
	validateSearch: (search: Record<string, unknown>): HomeSearchParams => {
		return {
			useDebug: search.useDebug as boolean,
			shareToken: (search.shareToken as string),
		}
	},
})
