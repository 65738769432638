import LoginPage from "../pages/LoginPage/LoginPage"
import { createFileRoute } from "@tanstack/react-router"

type LoginSearchParams = {
	useDebug?: boolean,
	shareToken?: string,
}

export const Route = createFileRoute("/login")({
	component: LoginPage,
	validateSearch: (search: Record<string, unknown>): LoginSearchParams => {
		return {
			useDebug: search.useDebug as boolean,
			shareToken: (search.shareToken as string),
		}
	},
})
