/* eslint-disable jsx-a11y/heading-has-content */
import React, { useContext, useEffect, useMemo, useRef } from "react"
import { forwardRef } from "react"
import TextPlayground from "./TextPlayground/TextPlayground"
import Styles from "./FontsPlaygroundDesktop.module.scss"
import { SanitizedElementProps } from "deblank-common/src/hooks/useSanitizedState"
import { Pairing } from "deblank-api-types"
import { useRecoilValue } from "recoil"
import { selectorsCommon } from "@/recoil/ConversationsRecord/Selectors/Common"
import { selectorsFontCache } from "@/recoil/FontCache/Selectors"
import { TextState } from "../FontsPlaygroundHelper"
import { TrackConversationEventContext } from "@/pages/AssistantPage/TrackConversationEventProvider"
import { useRouterAccess } from "@/hooks/useRouterAccess"

type Props = {
	body: {
		elementProps: SanitizedElementProps,
		onBlur: (e: React.FocusEvent<HTMLElement>) => void,
		textState: TextState,
		onChange: React.Dispatch<React.SetStateAction<TextState>>,
	},
	subtitle: {
		elementProps: SanitizedElementProps,
		onBlur: (e: React.FocusEvent<HTMLElement>) => void,
		textState: TextState,
		onChange: React.Dispatch<React.SetStateAction<TextState>>,
	},
	title: {
		elementProps: SanitizedElementProps,
		onBlur: (e: React.FocusEvent<HTMLElement>) => void,
		textState: TextState,
		onChange: React.Dispatch<React.SetStateAction<TextState>>,
	},
}

const FontsPlaygroundDesktop = forwardRef((
	props: Props,
	ref: React.ForwardedRef<HTMLDivElement>,
) => {
	const widget = useRecoilValue(selectorsCommon.fontPlaygroundWidget)
	const widgetSelectData = useMemo(() => (widget), [])
	const fontDetails = useRecoilValue(selectorsFontCache.fontFromCache(widgetSelectData?.widgetData.slug))
	const baseFontDetails = useRecoilValue(selectorsFontCache.fontFromCache(widgetSelectData?.metadata.baseFont || undefined))

	const titleRef = useRef<HTMLHeadingElement>(null)
	const { trackConversationEvent, } = useContext(TrackConversationEventContext)
	const { isReadOnly, } = useRouterAccess()



	useEffect(() => {
		if (titleRef.current) {
			const range = document.createRange()
			const sel = window.getSelection()

			range.setStart(titleRef.current.childNodes[0], widgetSelectData!.metadata.title.length)
			range.collapse(true)

			sel?.removeAllRanges()
			sel?.addRange(range)


			titleRef.current.focus()
		}
	}, [])

	if (!widgetSelectData) {
		return null
	}

	return (
		<div className={Styles.content} ref={ref}>
			<TextPlayground
				messageId={widgetSelectData.idRef.messageId}
				font={widgetSelectData.metadata.pairing === Pairing.paragraph
					? baseFontDetails!
					: fontDetails!
				}
				textState={props.title.textState}
				onChange={props.title.onChange}
			>
				<h2
					ref={titleRef}
					className={`${Styles.extend_styles} ${Styles.editable_text}`}
					style={widgetSelectData.metadata.pairing === Pairing.paragraph
						? baseFontDetails!.fontCSSProperties
						: fontDetails!.fontCSSProperties}
					contentEditable={!isReadOnly}
					dangerouslySetInnerHTML={{ __html: props.title.elementProps.dangerouslySetInnerHTML.__html, }}
					onPaste={props.title.elementProps.onPaste}
					spellCheck={false}
					onBlur={(e) => {
						trackConversationEvent({
							eventName: "ChangeText",
							textType: "title",
							fontSlug: widgetSelectData.widgetData.slug,
						})
						props.title.onBlur(e)
					}}
				/>
			</TextPlayground>

			<TextPlayground
				messageId={widgetSelectData.idRef.messageId}
				font={widgetSelectData.metadata.pairing === Pairing.title
					? baseFontDetails!
					: fontDetails!}
				textState={props.subtitle.textState}
				onChange={props.subtitle.onChange}
			>
				<h3
					className={`${Styles.extend_styles} ${Styles.editable_text}`}
					style={widgetSelectData.metadata.pairing === Pairing.title
						? baseFontDetails!.fontCSSProperties
						: fontDetails!.fontCSSProperties}
					contentEditable={!isReadOnly}
					dangerouslySetInnerHTML={{ __html: props.subtitle.elementProps.dangerouslySetInnerHTML.__html, }}
					onPaste={props.subtitle.elementProps.onPaste}
					spellCheck={false}
					onBlur={(e) => {
						trackConversationEvent({
							eventName: "ChangeText",
							textType: "subtitle",
							fontSlug: widgetSelectData.widgetData.slug,
						})
						props.subtitle.onBlur(e)
					}}
				/>
			</TextPlayground>

			<TextPlayground
				messageId={widgetSelectData.idRef.messageId}
				font={widgetSelectData.metadata.pairing === Pairing.title
					? baseFontDetails!
					: fontDetails!}
				textState={props.body.textState}
				onChange={props.body.onChange}
			>
				<p
					className={`${Styles.body} ${Styles.editable_text}`}
					style={widgetSelectData.metadata.pairing === Pairing.title
						? baseFontDetails!.fontCSSProperties
						: fontDetails!.fontCSSProperties}
					contentEditable={!isReadOnly}
					spellCheck={false}
					dangerouslySetInnerHTML={{ __html: props.body.elementProps.dangerouslySetInnerHTML.__html, }}
					onPaste={props.body.elementProps.onPaste}
					onBlur={(e) => {
						trackConversationEvent({
							eventName: "ChangeText",
							textType: "body",
							fontSlug: widgetSelectData.widgetData.slug,
						})
						props.body.onBlur(e)
					}}
				/>
			</TextPlayground>
		</div>
	)
})

FontsPlaygroundDesktop.displayName = "FontsPlaygroundDesktop"

export default FontsPlaygroundDesktop
